import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useOnScreen } from 'site-modules/shared/hooks/use-on-screen';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { getImageUrl, SOURCES } from 'client/utils/image-helpers';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';

import { HtmlVideoPlayer } from 'site-modules/shared/components/html-video-player/html-video-player';
import { CREATIVE_ID } from './constants';

function fireTracking(
  subactionName,
  trackingValue,
  trackingPosition,
  actionCategory = TrackingConstant.USER_ACTION_CATEGORY,
  actionCause = TrackingConstant.ACTION_CAUSE_BUTTON_CLICK
) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_category: actionCategory,
      action_cause: actionCause,
      action_name: TrackingConstant.ACTION_VIEW_CONTENT,
      creative_id: CREATIVE_ID,
      value: trackingValue,
      position: trackingPosition,
      subaction_name: subactionName,
    },
  });
}

export function VehicleShowcaseVideo({
  video,
  transcript,
  trackingValue,
  trackingPosition,
  isActive,
  videoAutoPlay,
  headline,
}) {
  const [isMounted, setMounted] = useState(false);
  const [isMuted, setMuted] = useState(true);
  const [isPlayback, setPlayback] = useState(false);
  const pausedByUser = useRef(false);

  const containerRef = useRef();
  const [isOnScreen] = useOnScreen(containerRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });
  const halfway = useRef(false);

  const isClonedSlide = useMemo(() => !(isMounted && !containerRef.current.closest('.slick-cloned')), [isMounted]);

  function onPlay() {
    if (!isClonedSlide) {
      fireTracking(TrackingConstant.VIDEO_START, trackingValue, trackingPosition);
    }
    pausedByUser.current = false;
    setPlayback(true);
  }

  function onPause() {
    if (!isClonedSlide) {
      fireTracking(TrackingConstant.VIDEO_PAUSE, trackingValue, trackingPosition);
    }
    if (isOnScreen && isActive) {
      pausedByUser.current = true;
    }
    setPlayback(false);
  }

  function onVolumeChange({ target: { muted } }) {
    if (!isClonedSlide) {
      fireTracking(muted ? 'audio_mute' : 'audio_unmute', trackingValue, trackingPosition);
      EventToolbox.fireCustomEvent('volume_change', { muted });
    }
  }

  function onTimeUpdate({ duration, currentTime }) {
    const half = duration / 2;
    if (!halfway.current && currentTime > half) {
      fireTracking(
        'video_halfway',
        trackingValue,
        trackingPosition,
        TrackingConstant.SYSTEM_ACTION_CATEGORY,
        TrackingConstant.SYSTEM_ACTION_CAUSE
      );
      halfway.current = true;
    } else if (halfway.current && currentTime < half) {
      // if video is not looped, you can use onEnded event to fire tracking
      fireTracking(
        'video_ended',
        trackingValue,
        trackingPosition,
        TrackingConstant.SYSTEM_ACTION_CATEGORY,
        TrackingConstant.SYSTEM_ACTION_CAUSE
      );
      halfway.current = false;
    }
  }

  useEffect(() => {
    function videoVolumeChange({ detail: { muted } }) {
      setMuted(muted);
    }
    EventToolbox.on('volume_change', videoVolumeChange);

    return () => {
      EventToolbox.off('volume_change', videoVolumeChange);
    };
  }, []);

  useEffect(() => {
    if (isOnScreen && !isMounted) {
      if (videoAutoPlay && isActive) {
        fireTracking(
          TrackingConstant.VIDEO_START,
          trackingValue,
          trackingPosition,
          TrackingConstant.SYSTEM_ACTION_CATEGORY,
          TrackingConstant.PAGE_LOAD_CAUSE
        );
        setPlayback(true);
      }
      setMounted(true);
    } else if (isMounted) {
      setPlayback(isActive && isOnScreen && !pausedByUser.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnScreen, isActive]);

  return (
    <AspectRatioContainer aspectRatio="16:9" className="w-100 rounded-8">
      <div className="w-100 rounded-8" ref={containerRef}>
        <HtmlVideoPlayer
          className="w-100 rounded-8"
          src={getImageUrl(video, null, SOURCES.STATIC)}
          track={getImageUrl(transcript, null, SOURCES.STATIC)}
          loop
          controls
          playsInline
          playback={!isClonedSlide ? isPlayback : null}
          muted={!isClonedSlide ? isMuted : true}
          onPlay={onPlay}
          onPause={onPause}
          onVolumeChange={onVolumeChange}
          onTimeUpdate={onTimeUpdate}
          aria-label={headline}
          tabIndex={isActive ? '0' : '-1'}
        />
      </div>
    </AspectRatioContainer>
  );
}

VehicleShowcaseVideo.propTypes = {
  video: PropTypes.string.isRequired,
  transcript: PropTypes.string,
  trackingValue: PropTypes.string,
  trackingPosition: PropTypes.string,
  isActive: PropTypes.bool,
  videoAutoPlay: PropTypes.bool,
  headline: PropTypes.string,
};

VehicleShowcaseVideo.defaultProps = {
  transcript: '',
  trackingValue: 'Video',
  trackingPosition: '1',
  isActive: false,
  videoAutoPlay: false,
  headline: null,
};
